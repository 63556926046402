<template>
  <div>
    <withdraw-view-header class="mb-1" :withdraw-data="withdrawData" :fetch-data="fetchData" />
  </div>
</template>
<script>
import store from '@/store';
import { BRow, BCol } from 'bootstrap-vue';
import { ref, onUnmounted } from '@vue/composition-api';
import router from '@/router';
import WithdrawViewHeader from './withdrawViewHeader.vue';
import investmentStoreModule from '../../investmentStoreModule';

export default {
  components: {
    BRow,
    BCol,
    WithdrawViewHeader,
  },
  setup() {
    const withdrawData = ref({});

    const USER_APP_STORE_MODULE_NAME = 'app-investment';

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, investmentStoreModule);
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
      }
    });
    function fetchData() {
      store
        .dispatch('app-investment/fetchDetailWithdraw', {
          id: router.currentRoute.params.id,
        })
        .then((response) => {
          withdrawData.value = response.data.data;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            withdrawData.value = undefined;
          }
        });
    }
    fetchData();
    return {
      withdrawData,
      fetchData,
    };
  },
};
</script>
<style lang="scss" scoped></style>
